import { FC, useState, useEffect } from "react";
import useSWR from "swr";
import { fetcher } from "../../utils/fetcher";
import logo from "../../images/logo.png";

type Props = {
  details: any;
};

export const NftCard: FC<Props> = ({ details }) => {
  const [fallbackImage, setFallbackImage] = useState(false);
  const { name, uri } = details?.data ?? {};

  const { data, error } = useSWR(uri, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const onImageError = () => setFallbackImage(true);
  const { image } = data ?? {};

  return (
    <div className={`card bordered max-w-xs compact rounded-md`}>
      <figure className="min-h-16 animation-pulse-color">
        {!fallbackImage || !error ? (
          <img
            src={image}
            onError={onImageError}
            className="bg-gray-800 object-cover"
            alt="bot"
          />
        ) : (
          // Fallback when preview isn't available
          // This could be broken image, video, or audio
          <div className="w-auto h-48 flex items-center justify-center bg-gray-900 bg-opacity-40">
            <img src={logo} alt="logo" />
          </div>
        )}
      </figure>
      <div className="card-body">
        <h2 className="card-title text-sm text-left">{name}</h2>
      </div>
    </div>
  );
};
