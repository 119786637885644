import { useWallet } from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import useWalletNfts from "../../hooks/use-wallet-nfts";
import { useState } from "react";
import { filterNFTs, nftInterface } from "../../utils/filter-nfts";

import "./BuildABot.scss";
import classNames from "classnames";
import useBotParts from "../../hooks/use-bot-parts";
import { NftCard } from "../../components/build-a-bot/NftCard";

const BuildABot = () => {
  const [selectedNft, setSelectedNft] = useState<null | nftInterface>(null);
  const [selectedBabyBot, setSelectedBabyBot] = useState<null | nftInterface>(
    null
  );

  const [isLoading, nfts]: any = useWalletNfts();

  const wallet = useWallet();
  const [heads, babyBots] = filterNFTs(nfts);
  const [composing, composedBot]: any = useBotParts(
    selectedNft,
    selectedBabyBot
  );

  return (
    <div className="build-a-bot">
      <nav className="build-a-bot__navbar flex justify-between items-center">
        <div className="flex">
          <div className="mr-4">bonsai</div>
          <div className="mr-4">Rarity</div>
          <div className="mr-4">Factory</div>
        </div>
        <div>
          <WalletModalProvider>
            <WalletMultiButton className="build-a-bot__connect-button" />
          </WalletModalProvider>
        </div>
      </nav>
      <div className="p-6">
        <div className="build-a-bot__welcome">
          {">>"}
          <br />
          WELCOME
          <br />
          TO // THE <br />
          FACTORY {">>"}
        </div>
        <div className="build-a-bot__main flex items-center justify-around mt-7">
          <div className="build-a-bot__container build-a-bot__container--inventory">
            <div style={{ borderBottom: "3px solid black" }}>
              INVENTORY
              <br />
              YOUR COMPONENTS
            </div>
            <div className="build-a-bot__inventory-list flex flex-wrap">
              {babyBots.map((item, index) => (
                <div key={index} style={{ flexBasis: "50%" }}>
                  <button
                    className={classNames("mb-2", {
                      "border-solid border-4 border-black":
                        item.data.name === selectedBabyBot?.data.name,
                    })}
                    onClick={() =>
                      setSelectedBabyBot(
                        item.data.name === selectedBabyBot?.data.name
                          ? null
                          : item
                      )
                    }
                  >
                    <div>
                      <NftCard key={index} details={item} />
                    </div>
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div
            className={classNames("build-a-bot__container", {
              "build-a-bot__container--composed": !!composedBot,
            })}
          >
            {(selectedNft && !composedBot) ||
              (!selectedBabyBot && selectedNft && (
                <div className="build-a-bot__selected-nft">
                  <NftCard key={selectedNft.data.name} details={selectedNft} />
                </div>
              ))}
            {/* {composing && !composedBot && (
              <div>
                <progress className="is-small is-primary build-a-bot__loader-bar" />
              </div>
            )} */}
            {selectedNft && selectedBabyBot && (
              <>
                {composedBot?.head && (
                  <div className="relative">
                    {composedBot?.head.map((part: any, index: number) => (
                      <img
                        key={index}
                        className="absolute build-a-bot__composed-part"
                        src={part.link}
                        alt={part.id}
                      />
                    ))}
                    {composedBot?.baby && (
                      <div className="build-a-bot__composed-baby">
                        {composedBot.baby.map((part: any, index: number) => (
                          <img
                            key={index}
                            className="build-a-bot__composed-part--baby build-a-bot__composed-part absolute"
                            src={part.link}
                            alt={part.id}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {isLoading && wallet.connected && (
          <div className="text-center mt-10">
            Fetching Irrelevants...
            <progress
              className="progress is-small is-primary build-a-bot__loader-bar"
              max="100"
            />
          </div>
        )}
        <div className="build-a-bot__bot-list mt-14">
          {!isLoading && wallet.connected && nfts.length === 0 && (
            <h1 className="text-lg text-black font-bold">
              You do not have &quot;NFTs&quot;
            </h1>
          )}
          <div className="flex flex-wrap justify-center">
            {heads.map((nft: nftInterface, index: number) => (
              <div
                key={index}
                className="mr-4 mb-4"
                style={{ flexBasis: "18%" }}
              >
                <button
                  style={
                    nft.data.name === selectedNft?.data?.name
                      ? { border: "10px solid #4cff9f" }
                      : {}
                  }
                  disabled={isLoading}
                  onClick={() =>
                    setSelectedNft(
                      nft.data.name === selectedNft?.data?.name ? null : nft
                    )
                  }
                >
                  <NftCard key={index} details={nft} />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildABot;
