import { useEffect, useState } from 'react';
import { nftInterface } from '../utils/filter-nfts';

interface BotPart {
  id: number;
  name: string;
  link: string;
}
interface ComposedBot {
  head: BotPart[];
  baby: BotPart[];
}

export default function useBotParts(nft: nftInterface | null, babyBot: nftInterface | null){
  const [isComposing, setIsComposing] = useState<boolean>(false)
  const [botImages, setBotImages] = useState<ComposedBot | null>(null)

  useEffect(() => {
    if(nft?.mint && babyBot?.mint){
      (async () => {
        setIsComposing(true)
        requestApi()
        setIsComposing(false)
      })();
    }
    async function requestApi(){
      setBotImages(null)
      const res = await fetch(
        `https://build-a-bot-ai-prod.herokuapp.com/api/dev_bot_parts?head=${nft?.mint}&baby=${babyBot?.mint}`
      )
      const json = await res.json()
      setBotImages(json)
    }
  }, [babyBot, nft])

  return [isComposing, botImages];
}
