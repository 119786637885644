export const wave3Hash: {[key: string]: string} = {
    "3558": "1235",
    "3559": "1244",
    "3560": "1334",
    "3561": "1335",
    "3562": "1336",
    "3563": "1337",
    "3564": "1338",
    "3565": "1339",
    "3566": "1340",
    "3567": "1341",
    "3568": "1342",
    "3569": "1343",
    "3570": "1245",
    "3571": "1344",
    "3572": "1345",
    "3573": "1346",
    "3574": "1347",
    "3575": "1348",
    "3576": "1349",
    "3577": "1350",
    "3578": "1351",
    "3579": "1352",
    "3580": "1353",
    "3581": "1246",
    "3582": "1354",
    "3583": "1355",
    "3584": "1356",
    "3585": "1357",
    "3586": "1358",
    "3587": "1359",
    "3588": "1360",
    "3589": "1361",
    "3590": "1362",
    "3591": "1363",
    "3592": "1247",
    "3593": "1364",
    "3594": "1365",
    "3595": "1366",
    "3596": "1367",
    "3597": "1368",
    "3598": "1369",
    "3599": "1370",
    "3600": "1371",
    "3601": "1372",
    "3602": "1373",
    "3603": "1248",
    "3604": "1374",
    "3605": "1375",
    "3606": "1376",
    "3607": "1377",
    "3608": "1378",
    "3609": "1379",
    "3610": "1380",
    "3611": "1381",
    "3612": "1382",
    "3613": "1383",
    "3614": "1249",
    "3615": "1384",
    "3616": "1385",
    "3617": "1386",
    "3618": "1387",
    "3619": "1388",
    "3620": "1389",
    "3621": "1390",
    "3622": "1391",
    "3623": "1392",
    "3624": "1393",
    "3625": "1250",
    "3626": "1394",
    "3627": "1395",
    "3628": "1396",
    "3629": "1397",
    "3630": "1398",
    "3631": "1399",
    "3632": "1400",
    "3633": "1401",
    "3634": "1402",
    "3635": "1403",
    "3636": "1251",
    "3637": "1404",
    "3638": "1405",
    "3639": "1406",
    "3640": "1407",
    "3641": "1408",
    "3642": "1409",
    "3643": "1410",
    "3644": "1411",
    "3645": "1412",
    "3646": "1413",
    "3647": "1252",
    "3648": "1414",
    "3649": "1415",
    "3650": "1416",
    "3651": "1417",
    "3652": "1418",
    "3653": "1419",
    "3654": "1420",
    "3655": "1421",
    "3656": "1422",
    "3657": "1423",
    "3658": "1253",
    "3659": "1424",
    "3660": "1425",
    "3661": "1426",
    "3662": "1427",
    "3663": "1428",
    "3664": "1429",
    "3665": "1430",
    "3666": "1431",
    "3667": "1432",
    "3668": "1433",
    "3669": "1236",
    "3670": "1254",
    "3671": "1434",
    "3672": "1435",
    "3673": "1436",
    "3674": "1437",
    "3675": "1438",
    "3676": "1439",
    "3677": "1440",
    "3678": "1441",
    "3679": "1442",
    "3680": "1443",
    "3681": "1255",
    "3682": "1444",
    "3683": "1445",
    "3684": "1446",
    "3685": "1447",
    "3686": "1448",
    "3687": "1449",
    "3688": "1450",
    "3689": "1451",
    "3690": "1452",
    "3691": "1453",
    "3692": "1256",
    "3693": "1454",
    "3694": "1455",
    "3695": "1456",
    "3696": "1457",
    "3697": "1458",
    "3698": "1459",
    "3699": "1460",
    "3700": "1461",
    "3701": "1462",
    "3702": "1463",
    "3703": "1257",
    "3704": "1464",
    "3705": "1465",
    "3706": "1466",
    "3707": "1467",
    "3708": "1468",
    "3709": "1469",
    "3710": "1470",
    "3711": "1471",
    "3712": "1472",
    "3713": "1473",
    "3714": "1258",
    "3715": "1474",
    "3716": "1475",
    "3717": "1476",
    "3718": "1477",
    "3719": "1478",
    "3720": "1479",
    "3721": "1480",
    "3722": "1481",
    "3723": "1482",
    "3724": "1483",
    "3725": "1259",
    "3726": "1484",
    "3727": "1485",
    "3728": "1486",
    "3729": "1487",
    "3730": "1488",
    "3731": "1489",
    "3732": "1490",
    "3733": "1491",
    "3734": "1492",
    "3735": "1493",
    "3736": "1260",
    "3737": "1494",
    "3738": "1495",
    "3739": "1496",
    "3740": "1497",
    "3741": "1498",
    "3742": "1499",
    "3743": "1500",
    "3744": "1501",
    "3745": "1502",
    "3746": "1503",
    "3747": "1261",
    "3748": "1504",
    "3749": "1505",
    "3750": "1506",
    "3751": "1507",
    "3752": "1508",
    "3753": "1509",
    "3754": "1510",
    "3755": "1511",
    "3756": "1512",
    "3757": "1513",
    "3758": "1262",
    "3759": "1514",
    "3760": "1515",
    "3761": "1516",
    "3762": "1517",
    "3763": "1518",
    "3764": "1519",
    "3765": "1520",
    "3766": "1521",
    "3767": "1522",
    "3768": "1523",
    "3769": "1263",
    "3770": "1524",
    "3771": "1525",
    "3772": "1526",
    "3773": "1527",
    "3774": "1528",
    "3775": "1529",
    "3776": "1530",
    "3777": "1531",
    "3778": "1532",
    "3779": "1533",
    "3780": "1237",
    "3781": "1264",
    "3782": "1534",
    "3783": "1535",
    "3784": "1536",
    "3785": "1537",
    "3786": "1538",
    "3787": "1539",
    "3788": "1540",
    "3789": "1541",
    "3790": "1542",
    "3791": "1543",
    "3792": "1265",
    "3793": "1544",
    "3794": "1545",
    "3795": "1546",
    "3796": "1547",
    "3797": "1548",
    "3798": "1549",
    "3799": "1550",
    "3800": "1551",
    "3801": "1552",
    "3802": "1553",
    "3803": "1266",
    "3804": "1554",
    "3805": "1555",
    "3806": "1556",
    "3807": "1557",
    "3808": "1558",
    "3809": "1559",
    "3810": "1560",
    "3811": "1561",
    "3812": "1562",
    "3813": "1563",
    "3814": "1267",
    "3815": "1564",
    "3816": "1565",
    "3817": "1566",
    "3818": "1567",
    "3819": "1568",
    "3820": "1569",
    "3821": "1570",
    "3822": "1571",
    "3823": "1572",
    "3824": "1573",
    "3825": "1268",
    "3826": "1574",
    "3827": "1575",
    "3828": "1576",
    "3829": "1577",
    "3830": "1578",
    "3831": "1579",
    "3832": "1580",
    "3833": "1581",
    "3834": "1582",
    "3835": "1583",
    "3836": "1269",
    "3837": "1584",
    "3838": "1585",
    "3839": "1586",
    "3840": "1587",
    "3841": "1588",
    "3842": "1589",
    "3843": "1590",
    "3844": "1591",
    "3845": "1592",
    "3846": "1593",
    "3847": "1270",
    "3848": "1594",
    "3849": "1595",
    "3850": "1596",
    "3851": "1597",
    "3852": "1598",
    "3853": "1599",
    "3854": "1600",
    "3855": "1601",
    "3856": "1602",
    "3857": "1603",
    "3858": "1271",
    "3859": "1604",
    "3860": "1605",
    "3861": "1606",
    "3862": "1607",
    "3863": "1608",
    "3864": "1609",
    "3865": "1610",
    "3866": "1611",
    "3867": "1612",
    "3868": "1613",
    "3869": "1272",
    "3870": "1614",
    "3871": "1615",
    "3872": "1616",
    "3873": "1617",
    "3874": "1618",
    "3875": "1619",
    "3876": "1620",
    "3877": "1621",
    "3878": "1622",
    "3879": "1623",
    "3880": "1273",
    "3881": "1624",
    "3882": "1625",
    "3883": "1626",
    "3884": "1627",
    "3885": "1628",
    "3886": "1629",
    "3887": "1630",
    "3888": "1631",
    "3889": "1632",
    "3890": "1633",
    "3891": "1238",
    "3892": "1274",
    "3893": "1634",
    "3894": "1635",
    "3895": "1636",
    "3896": "1637",
    "3897": "1638",
    "3898": "1639",
    "3899": "1640",
    "3900": "1641",
    "3901": "1642",
    "3902": "1643",
    "3903": "1275",
    "3904": "1644",
    "3905": "1645",
    "3906": "1646",
    "3907": "1647",
    "3908": "1648",
    "3909": "1649",
    "3910": "1650",
    "3911": "1651",
    "3912": "1652",
    "3913": "1653",
    "3914": "1276",
    "3915": "1654",
    "3916": "1655",
    "3917": "1656",
    "3918": "1657",
    "3919": "1658",
    "3920": "1659",
    "3921": "1660",
    "3922": "1661",
    "3923": "1662",
    "3924": "1663",
    "3925": "1277",
    "3926": "1664",
    "3927": "1665",
    "3928": "1666",
    "3929": "1667",
    "3930": "1668",
    "3931": "1669",
    "3932": "1670",
    "3933": "1671",
    "3934": "1672",
    "3935": "1673",
    "3936": "1278",
    "3937": "1674",
    "3938": "1675",
    "3939": "1676",
    "3940": "1677",
    "3941": "1678",
    "3942": "1679",
    "3943": "1680",
    "3944": "1681",
    "3945": "1682",
    "3946": "1683",
    "3947": "1279",
    "3948": "1684",
    "3949": "1685",
    "3950": "1686",
    "3951": "1687",
    "3952": "1688",
    "3953": "1689",
    "3954": "1690",
    "3955": "1691",
    "3956": "1692",
    "3957": "1693",
    "3958": "1280",
    "3959": "1694",
    "3960": "1695",
    "3961": "1696",
    "3962": "1697",
    "3963": "1698",
    "3964": "1699",
    "3965": "1700",
    "3966": "1701",
    "3967": "1702",
    "3968": "1703",
    "3969": "1281",
    "3970": "1704",
    "3971": "1705",
    "3972": "1706",
    "3973": "1707",
    "3974": "1708",
    "3975": "1709",
    "3976": "1710",
    "3977": "1711",
    "3978": "1712",
    "3979": "1713",
    "3980": "1282",
    "3981": "1714",
    "3982": "1715",
    "3983": "1716",
    "3984": "1717",
    "3985": "1718",
    "3986": "1719",
    "3987": "1720",
    "3988": "1721",
    "3989": "1722",
    "3990": "1723",
    "3991": "1283",
    "3992": "1724",
    "3993": "1725",
    "3994": "1726",
    "3995": "1727",
    "3996": "1728",
    "3997": "1729",
    "3998": "1730",
    "3999": "1731",
    "4000": "1732",
    "4001": "1733",
    "4002": "1239",
    "4003": "1284",
    "4004": "1734",
    "4005": "1735",
    "4006": "1736",
    "4007": "1737",
    "4008": "1738",
    "4009": "1739",
    "4010": "1740",
    "4011": "1741",
    "4012": "1742",
    "4013": "1743",
    "4014": "1285",
    "4015": "1744",
    "4016": "1745",
    "4017": "1746",
    "4018": "1747",
    "4019": "1748",
    "4020": "1749",
    "4021": "1750",
    "4022": "1751",
    "4023": "1752",
    "4024": "1753",
    "4025": "1286",
    "4026": "1754",
    "4027": "1755",
    "4028": "1756",
    "4029": "1757",
    "4030": "1758",
    "4031": "1759",
    "4032": "1760",
    "4033": "1761",
    "4034": "1762",
    "4035": "1763",
    "4036": "1287",
    "4037": "1764",
    "4038": "1765",
    "4039": "1766",
    "4040": "1767",
    "4041": "1768",
    "4042": "1769",
    "4043": "1770",
    "4044": "1771",
    "4045": "1772",
    "4046": "1773",
    "4047": "1288",
    "4048": "1774",
    "4049": "1775",
    "4050": "1776",
    "4051": "1777",
    "4052": "1778",
    "4053": "1779",
    "4054": "1780",
    "4055": "1781",
    "4056": "1782",
    "4057": "1783",
    "4058": "1289",
    "4059": "1784",
    "4060": "1785",
    "4061": "1786",
    "4062": "1787",
    "4063": "1788",
    "4064": "1789",
    "4065": "1790",
    "4066": "1791",
    "4067": "1792",
    "4068": "1793",
    "4069": "1290",
    "4070": "1794",
    "4071": "1795",
    "4072": "1796",
    "4073": "1797",
    "4074": "1798",
    "4075": "1799",
    "4076": "1800",
    "4077": "1801",
    "4078": "1802",
    "4079": "1803",
    "4080": "1291",
    "4081": "1804",
    "4082": "1805",
    "4083": "1806",
    "4084": "1807",
    "4085": "1808",
    "4086": "1809",
    "4087": "1810",
    "4088": "1811",
    "4089": "1812",
    "4090": "1813",
    "4091": "1292",
    "4092": "1814",
    "4093": "1815",
    "4094": "1816",
    "4095": "1817",
    "4096": "1818",
    "4097": "1819",
    "4098": "1820",
    "4099": "1821",
    "4100": "1822",
    "4101": "1823",
    "4102": "1293",
    "4103": "1824",
    "4104": "1825",
    "4105": "1826",
    "4106": "1827",
    "4107": "1828",
    "4108": "1829",
    "4109": "1830",
    "4110": "1831",
    "4111": "1832",
    "4112": "1833",
    "4113": "1240",
    "4114": "1294",
    "4115": "1834",
    "4116": "1835",
    "4117": "1836",
    "4118": "1837",
    "4119": "1838",
    "4120": "1839",
    "4121": "1840",
    "4122": "1295",
    "4123": "1296",
    "4124": "1297",
    "4125": "1298",
    "4126": "1299",
    "4127": "1300",
    "4128": "1301",
    "4129": "1302",
    "4130": "1303",
    "4131": "1241",
    "4132": "1304",
    "4133": "1305",
    "4134": "1306",
    "4135": "1307",
    "4136": "1308",
    "4137": "1309",
    "4138": "1310",
    "4139": "1311",
    "4140": "1312",
    "4141": "1313",
    "4142": "1242",
    "4143": "1314",
    "4144": "1315",
    "4145": "1316",
    "4146": "1317",
    "4147": "1318",
    "4148": "1319",
    "4149": "1320",
    "4150": "1321",
    "4151": "1322",
    "4152": "1323",
    "4153": "1243",
    "4154": "1324",
    "4155": "1325",
    "4156": "1326",
    "4157": "1327",
    "4158": "1328",
    "4159": "1329",
    "4160": "1330",
    "4161": "1331",
    "4162": "1332",
    "4163": "1333"
}

export const wave2Hash: {[key: string]: string} = {
    "1235": "3558",
    "1236": "3669",
    "1237": "3780",
    "1238": "3891",
    "1239": "4002",
    "1240": "4113",
    "1241": "4131",
    "1242": "4142",
    "1243": "4153",
    "1244": "3559",
    "1245": "3570",
    "1246": "3581",
    "1247": "3592",
    "1248": "3603",
    "1249": "3614",
    "1250": "3625",
    "1251": "3636",
    "1252": "3647",
    "1253": "3658",
    "1254": "3670",
    "1255": "3681",
    "1256": "3692",
    "1257": "3703",
    "1258": "3714",
    "1259": "3725",
    "1260": "3736",
    "1261": "3747",
    "1262": "3758",
    "1263": "3769",
    "1264": "3781",
    "1265": "3792",
    "1266": "3803",
    "1267": "3814",
    "1268": "3825",
    "1269": "3836",
    "1270": "3847",
    "1271": "3858",
    "1272": "3869",
    "1273": "3880",
    "1274": "3892",
    "1275": "3903",
    "1276": "3914",
    "1277": "3925",
    "1278": "3936",
    "1279": "3947",
    "1280": "3958",
    "1281": "3969",
    "1282": "3980",
    "1283": "3991",
    "1284": "4003",
    "1285": "4014",
    "1286": "4025",
    "1287": "4036",
    "1288": "4047",
    "1289": "4058",
    "1290": "4069",
    "1291": "4080",
    "1292": "4091",
    "1293": "4102",
    "1294": "4114",
    "1295": "4122",
    "1296": "4123",
    "1297": "4124",
    "1298": "4125",
    "1299": "4126",
    "1300": "4127",
    "1301": "4128",
    "1302": "4129",
    "1303": "4130",
    "1304": "4132",
    "1305": "4133",
    "1306": "4134",
    "1307": "4135",
    "1308": "4136",
    "1309": "4137",
    "1310": "4138",
    "1311": "4139",
    "1312": "4140",
    "1313": "4141",
    "1314": "4143",
    "1315": "4144",
    "1316": "4145",
    "1317": "4146",
    "1318": "4147",
    "1319": "4148",
    "1320": "4149",
    "1321": "4150",
    "1322": "4151",
    "1323": "4152",
    "1324": "4154",
    "1325": "4155",
    "1326": "4156",
    "1327": "4157",
    "1328": "4158",
    "1329": "4159",
    "1330": "4160",
    "1331": "4161",
    "1332": "4162",
    "1333": "4163",
    "1334": "3560",
    "1335": "3561",
    "1336": "3562",
    "1337": "3563",
    "1338": "3564",
    "1339": "3565",
    "1340": "3566",
    "1341": "3567",
    "1342": "3568",
    "1343": "3569",
    "1344": "3571",
    "1345": "3572",
    "1346": "3573",
    "1347": "3574",
    "1348": "3575",
    "1349": "3576",
    "1350": "3577",
    "1351": "3578",
    "1352": "3579",
    "1353": "3580",
    "1354": "3582",
    "1355": "3583",
    "1356": "3584",
    "1357": "3585",
    "1358": "3586",
    "1359": "3587",
    "1360": "3588",
    "1361": "3589",
    "1362": "3590",
    "1363": "3591",
    "1364": "3593",
    "1365": "3594",
    "1366": "3595",
    "1367": "3596",
    "1368": "3597",
    "1369": "3598",
    "1370": "3599",
    "1371": "3600",
    "1372": "3601",
    "1373": "3602",
    "1374": "3604",
    "1375": "3605",
    "1376": "3606",
    "1377": "3607",
    "1378": "3608",
    "1379": "3609",
    "1380": "3610",
    "1381": "3611",
    "1382": "3612",
    "1383": "3613",
    "1384": "3615",
    "1385": "3616",
    "1386": "3617",
    "1387": "3618",
    "1388": "3619",
    "1389": "3620",
    "1390": "3621",
    "1391": "3622",
    "1392": "3623",
    "1393": "3624",
    "1394": "3626",
    "1395": "3627",
    "1396": "3628",
    "1397": "3629",
    "1398": "3630",
    "1399": "3631",
    "1400": "3632",
    "1401": "3633",
    "1402": "3634",
    "1403": "3635",
    "1404": "3637",
    "1405": "3638",
    "1406": "3639",
    "1407": "3640",
    "1408": "3641",
    "1409": "3642",
    "1410": "3643",
    "1411": "3644",
    "1412": "3645",
    "1413": "3646",
    "1414": "3648",
    "1415": "3649",
    "1416": "3650",
    "1417": "3651",
    "1418": "3652",
    "1419": "3653",
    "1420": "3654",
    "1421": "3655",
    "1422": "3656",
    "1423": "3657",
    "1424": "3659",
    "1425": "3660",
    "1426": "3661",
    "1427": "3662",
    "1428": "3663",
    "1429": "3664",
    "1430": "3665",
    "1431": "3666",
    "1432": "3667",
    "1433": "3668",
    "1434": "3671",
    "1435": "3672",
    "1436": "3673",
    "1437": "3674",
    "1438": "3675",
    "1439": "3676",
    "1440": "3677",
    "1441": "3678",
    "1442": "3679",
    "1443": "3680",
    "1444": "3682",
    "1445": "3683",
    "1446": "3684",
    "1447": "3685",
    "1448": "3686",
    "1449": "3687",
    "1450": "3688",
    "1451": "3689",
    "1452": "3690",
    "1453": "3691",
    "1454": "3693",
    "1455": "3694",
    "1456": "3695",
    "1457": "3696",
    "1458": "3697",
    "1459": "3698",
    "1460": "3699",
    "1461": "3700",
    "1462": "3701",
    "1463": "3702",
    "1464": "3704",
    "1465": "3705",
    "1466": "3706",
    "1467": "3707",
    "1468": "3708",
    "1469": "3709",
    "1470": "3710",
    "1471": "3711",
    "1472": "3712",
    "1473": "3713",
    "1474": "3715",
    "1475": "3716",
    "1476": "3717",
    "1477": "3718",
    "1478": "3719",
    "1479": "3720",
    "1480": "3721",
    "1481": "3722",
    "1482": "3723",
    "1483": "3724",
    "1484": "3726",
    "1485": "3727",
    "1486": "3728",
    "1487": "3729",
    "1488": "3730",
    "1489": "3731",
    "1490": "3732",
    "1491": "3733",
    "1492": "3734",
    "1493": "3735",
    "1494": "3737",
    "1495": "3738",
    "1496": "3739",
    "1497": "3740",
    "1498": "3741",
    "1499": "3742",
    "1500": "3743",
    "1501": "3744",
    "1502": "3745",
    "1503": "3746",
    "1504": "3748",
    "1505": "3749",
    "1506": "3750",
    "1507": "3751",
    "1508": "3752",
    "1509": "3753",
    "1510": "3754",
    "1511": "3755",
    "1512": "3756",
    "1513": "3757",
    "1514": "3759",
    "1515": "3760",
    "1516": "3761",
    "1517": "3762",
    "1518": "3763",
    "1519": "3764",
    "1520": "3765",
    "1521": "3766",
    "1522": "3767",
    "1523": "3768",
    "1524": "3770",
    "1525": "3771",
    "1526": "3772",
    "1527": "3773",
    "1528": "3774",
    "1529": "3775",
    "1530": "3776",
    "1531": "3777",
    "1532": "3778",
    "1533": "3779",
    "1534": "3782",
    "1535": "3783",
    "1536": "3784",
    "1537": "3785",
    "1538": "3786",
    "1539": "3787",
    "1540": "3788",
    "1541": "3789",
    "1542": "3790",
    "1543": "3791",
    "1544": "3793",
    "1545": "3794",
    "1546": "3795",
    "1547": "3796",
    "1548": "3797",
    "1549": "3798",
    "1550": "3799",
    "1551": "3800",
    "1552": "3801",
    "1553": "3802",
    "1554": "3804",
    "1555": "3805",
    "1556": "3806",
    "1557": "3807",
    "1558": "3808",
    "1559": "3809",
    "1560": "3810",
    "1561": "3811",
    "1562": "3812",
    "1563": "3813",
    "1564": "3815",
    "1565": "3816",
    "1566": "3817",
    "1567": "3818",
    "1568": "3819",
    "1569": "3820",
    "1570": "3821",
    "1571": "3822",
    "1572": "3823",
    "1573": "3824",
    "1574": "3826",
    "1575": "3827",
    "1576": "3828",
    "1577": "3829",
    "1578": "3830",
    "1579": "3831",
    "1580": "3832",
    "1581": "3833",
    "1582": "3834",
    "1583": "3835",
    "1584": "3837",
    "1585": "3838",
    "1586": "3839",
    "1587": "3840",
    "1588": "3841",
    "1589": "3842",
    "1590": "3843",
    "1591": "3844",
    "1592": "3845",
    "1593": "3846",
    "1594": "3848",
    "1595": "3849",
    "1596": "3850",
    "1597": "3851",
    "1598": "3852",
    "1599": "3853",
    "1600": "3854",
    "1601": "3855",
    "1602": "3856",
    "1603": "3857",
    "1604": "3859",
    "1605": "3860",
    "1606": "3861",
    "1607": "3862",
    "1608": "3863",
    "1609": "3864",
    "1610": "3865",
    "1611": "3866",
    "1612": "3867",
    "1613": "3868",
    "1614": "3870",
    "1615": "3871",
    "1616": "3872",
    "1617": "3873",
    "1618": "3874",
    "1619": "3875",
    "1620": "3876",
    "1621": "3877",
    "1622": "3878",
    "1623": "3879",
    "1624": "3881",
    "1625": "3882",
    "1626": "3883",
    "1627": "3884",
    "1628": "3885",
    "1629": "3886",
    "1630": "3887",
    "1631": "3888",
    "1632": "3889",
    "1633": "3890",
    "1634": "3893",
    "1635": "3894",
    "1636": "3895",
    "1637": "3896",
    "1638": "3897",
    "1639": "3898",
    "1640": "3899",
    "1641": "3900",
    "1642": "3901",
    "1643": "3902",
    "1644": "3904",
    "1645": "3905",
    "1646": "3906",
    "1647": "3907",
    "1648": "3908",
    "1649": "3909",
    "1650": "3910",
    "1651": "3911",
    "1652": "3912",
    "1653": "3913",
    "1654": "3915",
    "1655": "3916",
    "1656": "3917",
    "1657": "3918",
    "1658": "3919",
    "1659": "3920",
    "1660": "3921",
    "1661": "3922",
    "1662": "3923",
    "1663": "3924",
    "1664": "3926",
    "1665": "3927",
    "1666": "3928",
    "1667": "3929",
    "1668": "3930",
    "1669": "3931",
    "1670": "3932",
    "1671": "3933",
    "1672": "3934",
    "1673": "3935",
    "1674": "3937",
    "1675": "3938",
    "1676": "3939",
    "1677": "3940",
    "1678": "3941",
    "1679": "3942",
    "1680": "3943",
    "1681": "3944",
    "1682": "3945",
    "1683": "3946",
    "1684": "3948",
    "1685": "3949",
    "1686": "3950",
    "1687": "3951",
    "1688": "3952",
    "1689": "3953",
    "1690": "3954",
    "1691": "3955",
    "1692": "3956",
    "1693": "3957",
    "1694": "3959",
    "1695": "3960",
    "1696": "3961",
    "1697": "3962",
    "1698": "3963",
    "1699": "3964",
    "1700": "3965",
    "1701": "3966",
    "1702": "3967",
    "1703": "3968",
    "1704": "3970",
    "1705": "3971",
    "1706": "3972",
    "1707": "3973",
    "1708": "3974",
    "1709": "3975",
    "1710": "3976",
    "1711": "3977",
    "1712": "3978",
    "1713": "3979",
    "1714": "3981",
    "1715": "3982",
    "1716": "3983",
    "1717": "3984",
    "1718": "3985",
    "1719": "3986",
    "1720": "3987",
    "1721": "3988",
    "1722": "3989",
    "1723": "3990",
    "1724": "3992",
    "1725": "3993",
    "1726": "3994",
    "1727": "3995",
    "1728": "3996",
    "1729": "3997",
    "1730": "3998",
    "1731": "3999",
    "1732": "4000",
    "1733": "4001",
    "1734": "4004",
    "1735": "4005",
    "1736": "4006",
    "1737": "4007",
    "1738": "4008",
    "1739": "4009",
    "1740": "4010",
    "1741": "4011",
    "1742": "4012",
    "1743": "4013",
    "1744": "4015",
    "1745": "4016",
    "1746": "4017",
    "1747": "4018",
    "1748": "4019",
    "1749": "4020",
    "1750": "4021",
    "1751": "4022",
    "1752": "4023",
    "1753": "4024",
    "1754": "4026",
    "1755": "4027",
    "1756": "4028",
    "1757": "4029",
    "1758": "4030",
    "1759": "4031",
    "1760": "4032",
    "1761": "4033",
    "1762": "4034",
    "1763": "4035",
    "1764": "4037",
    "1765": "4038",
    "1766": "4039",
    "1767": "4040",
    "1768": "4041",
    "1769": "4042",
    "1770": "4043",
    "1771": "4044",
    "1772": "4045",
    "1773": "4046",
    "1774": "4048",
    "1775": "4049",
    "1776": "4050",
    "1777": "4051",
    "1778": "4052",
    "1779": "4053",
    "1780": "4054",
    "1781": "4055",
    "1782": "4056",
    "1783": "4057",
    "1784": "4059",
    "1785": "4060",
    "1786": "4061",
    "1787": "4062",
    "1788": "4063",
    "1789": "4064",
    "1790": "4065",
    "1791": "4066",
    "1792": "4067",
    "1793": "4068",
    "1794": "4070",
    "1795": "4071",
    "1796": "4072",
    "1797": "4073",
    "1798": "4074",
    "1799": "4075",
    "1800": "4076",
    "1801": "4077",
    "1802": "4078",
    "1803": "4079",
    "1804": "4081",
    "1805": "4082",
    "1806": "4083",
    "1807": "4084",
    "1808": "4085",
    "1809": "4086",
    "1810": "4087",
    "1811": "4088",
    "1812": "4089",
    "1813": "4090",
    "1814": "4092",
    "1815": "4093",
    "1816": "4094",
    "1817": "4095",
    "1818": "4096",
    "1819": "4097",
    "1820": "4098",
    "1821": "4099",
    "1822": "4100",
    "1823": "4101",
    "1824": "4103",
    "1825": "4104",
    "1826": "4105",
    "1827": "4106",
    "1828": "4107",
    "1829": "4108",
    "1830": "4109",
    "1831": "4110",
    "1832": "4111",
    "1833": "4112",
    "1834": "4115",
    "1835": "4116",
    "1836": "4117",
    "1837": "4118",
    "1838": "4119",
    "1839": "4120",
    "1840": "4121",
}
