import * as anchor from "@project-serum/anchor";
import axios from "axios";
import {
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import { programs } from '@metaplex/js';

const { metadata: { Metadata } } = programs

export const CANDY_MACHINE_PROGRAM = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

export async function getNftsForOwner(connection: anchor.web3.Connection, ownerAddress: anchor.web3.PublicKey) {
  const allTokens: any = []
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(ownerAddress, {
    programId: TOKEN_PROGRAM_ID
  });

  const totalNftAmount = tokenAccounts.value.length
  for (let index = 0; index < totalNftAmount; index++) {
    const tokenAccount = tokenAccounts.value[index];
    const tokenAmount = tokenAccount.account.data.parsed.info.tokenAmount;

    if (tokenAmount.amount === "1" && tokenAmount.decimals === 0) {
      let [pda] = await anchor.web3.PublicKey.findProgramAddress([
        Buffer.from("metadata"),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        (new anchor.web3.PublicKey(tokenAccount.account.data.parsed.info.mint)).toBuffer(),
      ], TOKEN_METADATA_PROGRAM_ID);
      const accountInfo: any = await connection.getParsedAccountInfo(pda);

      const metadata: any = new Metadata(ownerAddress.toString(), accountInfo.value);
      const name = metadata?.data?.data?.name
      if(name.toLowerCase().includes('irrelevant') || name.toLowerCase().includes('baby bot')){
        allTokens.push({mint: metadata.data.mint, data: metadata.data.data})
      }
    }

    allTokens.sort(function (a: any, b: any) {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    })
  }

  return [allTokens, totalNftAmount]
}