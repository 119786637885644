export interface nftInterface {
  data: {
    name: string
    uri: string
  }
  mint: string
}

export function filterNFTs(nfts: any){
  if(!nfts && nfts.length === 0){
    return [[], []]
  }
  let heads: nftInterface[] = []
  let babyBots: nftInterface[] = []

  for(let i = 0; i<nfts.length; i++){
    if(nfts[i].data.name.toLowerCase().includes('baby bot')){
      babyBots.push(nfts[i])
    }
    else if(nfts[i].data.name.toLowerCase().includes('irrelevant')){
      heads.push(nfts[i])
    }
  }

  return [heads, babyBots]
}