export enum RarityTypes {
  COMMON = 'COMMON',
  RARE = 'RARE',
  EPIC = 'EPIC',
  LEGENDARY = 'LEGENDARY',
  UNKNOWN = 'UNKNOWN'
}

export const rarityHashMap = {
  'PINK': RarityTypes.COMMON,
  'CITRUS': RarityTypes.COMMON,
  'SKY': RarityTypes.COMMON,
  'SILVER': RarityTypes.COMMON,
  'COBALT':RarityTypes.COMMON,
  'ROSEQUARTZ': RarityTypes.COMMON,
  'NUCLEAR': RarityTypes.COMMON,
  'OH': RarityTypes.COMMON,
  'CRYING': RarityTypes.COMMON,
  'ZEN': RarityTypes.COMMON,
  'GRATEFUL': RarityTypes.COMMON,
  'RECEIVER': RarityTypes.COMMON,
  'SPROUT': RarityTypes.COMMON,
  'ANTENNAE': RarityTypes.COMMON,
  'HANABI': RarityTypes.COMMON,
  'SCUFFED': RarityTypes.RARE,
  'ABUSED': RarityTypes.COMMON,
  'OCEAN': RarityTypes.RARE,
  'FRUIT': RarityTypes.RARE,
  'MIDNIGHT': RarityTypes.RARE,
  'COPPER': RarityTypes.RARE,
  'GOLD': RarityTypes.RARE,
  'RUSTY': RarityTypes.RARE,
  'AMETHYST': RarityTypes.RARE,
  'TITANIUM': RarityTypes.RARE,
  'SHORTCIRCUIT': RarityTypes.RARE,
  'WINKY': RarityTypes.RARE,
  'STARSTRUCK': RarityTypes.RARE,
  'GRILLS': RarityTypes.RARE,
  'TRADITIONAL': RarityTypes.EPIC,
  'PLASMA': RarityTypes.RARE,
  'DAISY': RarityTypes.RARE,
  'PRISTINE': RarityTypes.EPIC,
  'ENERGYSWORD': RarityTypes.RARE,
  'STABBED': RarityTypes.RARE,
  'GRAPH': RarityTypes.EPIC,
  'FUTURE': RarityTypes.EPIC,
  'ZWAVES': RarityTypes.EPIC,
  'INFRARED': RarityTypes.EPIC,
  'DIAMOND': RarityTypes.EPIC,
  'GRAPHITE': RarityTypes.EPIC,
  'RAINBOW': RarityTypes.EPIC,
  'TERMINATOR': RarityTypes.EPIC,
  'COOLCHAMELEON': RarityTypes.EPIC,
  'GUM': RarityTypes.EPIC,
  'BOT': RarityTypes.EPIC,
  'FRIEDEGG': RarityTypes.EPIC,
  'THENEST': RarityTypes.EPIC,
  'SOLSWORD': RarityTypes.EPIC,
  'FLAMECHAMELEON': RarityTypes.EPIC,
  'BABYBOT': RarityTypes.EPIC,
  'BABYBOTTRIPLETS': RarityTypes.LEGENDARY,
  'MATTE': RarityTypes.LEGENDARY,
  'BLACKONBLACK': RarityTypes.LEGENDARY,
  'CONSTELLATION':RarityTypes.LEGENDARY,
  'FUTUREWAVE': RarityTypes.EPIC,
  'CRYSTALBLUE': RarityTypes.LEGENDARY,
  'CRYSTALRED': RarityTypes.LEGENDARY,
  'MATTEBLACK': RarityTypes.LEGENDARY,
  'CRYSTALYELLOW': RarityTypes.LEGENDARY,
  'CRYSTALWHITE': RarityTypes.LEGENDARY,
  'SCREENTIME': RarityTypes.LEGENDARY,
  'MECHAMASK': RarityTypes.LEGENDARY,
  'DOUBLECOOLCHAMELEON': RarityTypes.EPIC,
  'GIGASTABBED': RarityTypes.LEGENDARY,
  'DOUBLEFLAMECHAMELEON': RarityTypes.LEGENDARY,
  'DOUBLEFROSTCHAMELEON': RarityTypes.LEGENDARY,
  'FROSTCHAMELEON': RarityTypes.EPIC,
  'SOLANA': RarityTypes.UNKNOWN,
  'MILKYWAY': RarityTypes.UNKNOWN,
  'ROVER': RarityTypes.UNKNOWN,
  'THECLAW': RarityTypes.UNKNOWN,
  'CUTE': RarityTypes.UNKNOWN,
  'IMMACULATE': RarityTypes.UNKNOWN,
  'PERFECTLYCRISPEDTOAST': RarityTypes.UNKNOWN,
  'CALMWATER': RarityTypes.UNKNOWN,
  'ZENGARDEN': RarityTypes.UNKNOWN,
  'SPOOKY': RarityTypes.UNKNOWN,
  'ZOMBIE': RarityTypes.UNKNOWN,
  'GIGAPLASMA': RarityTypes.UNKNOWN,
  'ALOT': RarityTypes.UNKNOWN,
  'NORMAL': RarityTypes.UNKNOWN,
  'PUZZLED': RarityTypes.UNKNOWN,
  'GRAPH PAPER': RarityTypes.UNKNOWN,
  'JUNGLE': RarityTypes.UNKNOWN,
  'SACREDSTAR': RarityTypes.UNKNOWN,
  'CONCRETEJUNGLE': RarityTypes.UNKNOWN,
  'GARDEN': RarityTypes.UNKNOWN,
  'CRACKED': RarityTypes.UNKNOWN,
  'DOUBLECHAMELEONDAO': RarityTypes.LEGENDARY,
  'MONOCHROME':RarityTypes.LEGENDARY,
  'NEAPOLITAN':RarityTypes.LEGENDARY,
  'BONSAI':RarityTypes.LEGENDARY,
  'DARKMODE':RarityTypes.LEGENDARY,
  'INVERTED':RarityTypes.LEGENDARY,
  'LIMBO':RarityTypes.LEGENDARY,
  'BABYBOTBLOCKPARTY':RarityTypes.LEGENDARY,
  'ECLIPSE':RarityTypes.LEGENDARY,
  'THUNDERGODCHAMELEON':RarityTypes.LEGENDARY,
  'CAT':RarityTypes.LEGENDARY,
}
